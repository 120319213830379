.aboutSection {
  overflow: hidden;
  text-align: center;  /* Light background color */
  height: 85vh;
  padding: 0vh 10vw;
  position: relative;
}

@media (max-height: 860px) {
  .aboutSection {
    height: fit-content;
  }
}

.sectionTitle {
  font-size: 4rem;
  font-weight: 400;
  line-height: 84.41px;
  text-align: center;
  margin: 0;
  margin-bottom: 1em;
}

.circle {
  z-index: -1;
  position: absolute;
  right: -3vw;
  bottom: 0;
  width: 30vw;
  transform: translateX(0);
  height: 30vw;
  border-radius: 50%;
  border: 1px solid #313131;
}

.cardContainer {
  display: grid;
  max-width: 900px;
  grid-template-areas: 
    "narrow1 wide1 wide1"
    "wide2 wide2 narrow2";
  gap: 2vw;
  margin: 0 auto;
}

.card {
  border: 1px solid #313131;
  background-color: #f1f1f1;
  padding: 1rem;
  text-align: left;
  opacity: 0;
  min-height: 10rem;
}

.card:nth-child(1) {
  grid-area: narrow1;
  border: 1px solid #e53935;
}

.card:nth-child(2) {
  grid-area: wide1;
}

.card:nth-child(3) {
  grid-area: wide2;
}

.card:nth-child(4) {
  grid-area: narrow2;
  border: 1px solid #e53935;
}

.card.animate:nth-child(1) {
  animation: slideInFromLeft 1s ease-in-out forwards;
}

.card.animate:nth-child(2) {
  animation: slideInFromRight 1s ease-in-out forwards;
  animation-delay: 0.5s; /* Delay for the second card */
}

.card.animate:nth-child(3) {
  animation: slideInFromLeft 1s ease-in-out forwards;
  animation-delay: 1s; /* Delay for the third card */
}

.card.animate:nth-child(4) {
  animation: slideInFromRight 1s ease-in-out forwards;
  animation-delay: 1.5s; /* Delay for the fourth card */
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100vw);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100vw);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}



.card .redline {
  position: absolute;
  width: 0%;
  height: 1px;
  background: #e53935;
  top: -1px;
}

.card.animate .blackline {
  position: absolute;
  height: 0%;
  width: 1px;
  max-width: 1px;
  background-color: #313131;
  top: 0px;
  right: -1px;
  animation: prolong-y 1.5s ease-in-out forwards;
  animation-delay: 3s;
}

.card.animate:nth-child(1) .redline{
  right: 0;
  animation: prolong-x 1.5s ease-in-out forwards;
  animation-delay: 3s;
}

.card.animate:nth-child(4) .redline{
  left: 0;
  animation: prolong-x 1.5s ease-in-out forwards;
  animation-delay: 3s;
}

@keyframes prolong-x {
  0% {
    width: 0%;
  }
  100% {
    width: 500%;
  }
}

@keyframes prolong-y {
  0% {
    height: 0%;
  }
  100% {
    height: 150%;
  }
}

.cardTitle {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 31.65px;
  text-align: left;
  margin: 0;
}

.cardText {
  font-family: 'Roboto Flex';
  font-size: 15px;
  font-weight: 400;
  line-height: 17.58px;
  text-align: left;

}

.iconContainer img{
  width: 100%;
}

@media (max-width: 960px) {
  .aboutSection {
    height: fit-content;
  }
  .cardContainer {
    max-width: 90%;
    display: flex;
    flex-direction: column;
  }
  .card {
    min-height: fit-content;
    opacity: 1;
  }
  .card.animate:nth-child(1),
  .card.animate:nth-child(2),
  .card.animate:nth-child(3),
  .card.animate:nth-child(4)
  {
    animation: none;
  }
  .sectionTitle {
    font-size: 3rem;
    line-height: normal;
  }


  .card .redline {
    position: absolute;
    width: 150%;
    height: 1px;
    background: #e53935;
    top: -1px;
  }

  .card .blackline {
    position: absolute;
    height: 150%;
    width: 1px;
    max-width: 1px;
    background-color: #313131;
    top: 0px;
    right: -1px;
    animation: none;
  }

  .card:nth-child(1) .redline{
    right: 0;
    animation: none;
  }

  .card:nth-child(4) .redline{
    left: 0;
    animation: none;
  }
  
  .circle {
    display: none;
  }
}