body {
  background: #F1F1F1;
}

.appContainer {

}

.contact-page {
  background-color: #313131;
  height: 100vh;
  width: 100vw; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-page .close-button {
  cursor: pointer;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 40px;
  right: 40px;
}


.hidden {
  visibility: hidden;
  position: fixed;
  z-index: -1;
}

.visible {
  visibility: visible;
  position: fixed;
  z-index: 10;
}



@media (max-width: 768px) {
  .contact-page {
    height: 100vh;
  }
  .contact-page .close-button{
    width: 30px;
    height: 30px;
  }
}