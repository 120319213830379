.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7vh 10vw;
  color: #ffffff; /* White text color */
  background-color: #313131;
  border-top: 1px solid #F1F1F1;
}

.leftblock {
  font-size: 20px;
  font-weight: 400;
  line-height: 26.38px;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  translate: 0 0.5rem;
}

.logo span {
  color: #F20505;
}

.mail {
  font-family: 'Roboto Flex', serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 17.58px;
  text-align: center;
}

.middleblock {
  font-family: 'Roboto Flex', sans-serif;
  color: #F1F1F1;
  font-size: 15px;
  font-weight: 400;
  line-height: 17.58px;
  text-align: center;
}

.rightblock {
  font-family: 'Roboto Flex', sans-serif;
  border: 1px solid #FFFFFF;
}

.button {
  border: 1px solid #ffffff; /* White border */
  padding: 1rem 2rem;
  color: #ffffff;
  background-color: transparent;
  cursor: pointer;
  transition: color 0.4s ease;
  font-size: 15px;
  font-weight: 400;
  line-height: 17.58px;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.button::before {
  content: '';
  position: absolute;
  bottom: -50%;
  left: 100%;
  width: 100px;
  height: 100px;
  background: #F20505;
  /* Red background */
  border-radius: 50%;
  /* Rounded stripe on the right */
  transition: transform 1.5s ease;
  /* Transition for the background */
  transform: scale(1);
  /* Start off-screen to the right */
}


.button:hover::before {
  transform: scale(10);
  /* Move the background to the left */
}

.button span {
  position: relative;
  z-index: 1;
  /* Ensure the text stays above the background */
}

a {
  text-decoration: none;
  color: inherit;
}

@media (max-width: 768px) {
.leftblock {
  align-items: center;
}
.footer {
  flex-direction: column;
  gap: 1rem;
}
}